import React from "react";
import Header from "../components/layout/Header";
export default function NousContacter() {
  return (
    <>
      <Header />
      <section id="contact-hero">
        <div className="hero-bg"></div>
      </section>
      <section id="contact-us">
        <div className="container">
          <div className="contact-us_warper">
            <div className="heading">
              <h1>
                VOUS AVEZ UNE QUESTION ? <br />
                UNE IDÉE À NOUS SOUMETTRE ?
              </h1>
              <p>Toute l’équipe de Nomade est à votre écoute</p>
            </div>
            <form action="" className="contact-us_form">
              <input placeholder="Votre nom" type="text" name="last-name" />
              <input placeholder="Votre prénom" type="text" name="first-name" />
              <input placeholder="Votre téléphone" type="number" name="phone" />
              <input placeholder="Votre email" type="email" name="email" />
              <select>
                <option value="">Demande d'information</option>
              </select>
              <textarea placeholder="Votre message" name="message"></textarea>
              <div className="contact-us_errors"></div>
              <p className="contact-us_info">
                Les données à caractère personnel recueillies font l’objet d’un
                traitement dont le responsable est La Poste conformément à la
                réglementation relative à la protection des données à caractère
                personnel. <br /> <br /> Elles sont traitées pour la gestion de
                vos demandes de renseignement. Vous disposez d’un droit d’accès,
                de rectification, d'effacement de vos données et d'un droit
                d’opposition et de limitation de leur traitement que vous pouvez
                exercer, en précisant vos nom, prénom, adresse postale en
                joignant une copie recto-verso de votre pièce d’identité : - Via
                <br /> <br />
                le formulaire en ligne :
                https://nomade-laposte.fr/nous-contacter/ - Ou à l’adresse
                suivante : espacenomade@laposte.fr
              </p>
              <button type="submit">Envoyer</button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

import React, { useEffect } from "react";
import { Link } from "gatsby";
import $ from "jquery";
export default function Header() {
  useEffect(() => {
    $("html").on("DOMMouseScroll mousewheel", function (e) {
      if (e.originalEvent.detail > 0 || e.originalEvent.wheelDelta < 0) {
        //alternative options for wheelData: wheelDeltaX & wheelDeltaY
        //scroll down
        // console.log("Down")
        $("#header-nav").addClass("hide-nav-bar");
      } else {
        //scroll up
        // console.log("Up")
        $("#header-nav").removeClass("hide-nav-bar");
      }
      //prevent page fom scrolling
      //return false;
    });
    $("body").addClass("js");
    var $menu = $("#menu"),
      $menulink = $(".menu-link");

    $menulink.click(function () {
      $menulink.toggleClass("active");
      $menu.toggleClass("active");
      return false;
    });
  });
  return (
    <div id="header-nav">
      <div className="container">
        <div className="warper-bs">
          <div className="warper">
            <div className="brand">
              <Link to="/">
                <img src="/img/culture.png" alt="minstère de la culture" />
              </Link>
            </div>
            <a href="#menu" className="menu-link">
              <span className="bar1"></span>
              <span className="bar2"></span>
              <span className="bar3"></span>
            </a>
          </div>
          <nav id="menu" role="navigation">
            <ul>
              <li>
                <Link to="/trouver-un-espace">trouver un espace</Link>
              </li>
              <li>
                <Link to="/comment-ca-fonctionne">comment ça fonctionne</Link>
              </li>
              <li>
                <Link to="/c'est-quoi-theoffice">THEOFFICE, c'est quoi?</Link>
              </li>
              <li>
                <Link to="/nous-contacter">nous contacter</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}
